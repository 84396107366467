var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"columns"},[_c('button',{staticClass:"btn",on:{"click":_vm.addMessage}},[_vm._v("Dodaj edycje")]),(_vm.newMessage)?_c('edition-form',{attrs:{"newMessage":_vm.newMessage,"itemId":_vm.itemId,"editingModel":_vm.editingModel,"requestUrl":'api/edition',"formLayout":[
      {
        formField: 'input',
        name: 'Tytuł Edycji',
        placeholder: 'Tytuł edycji',
        model: 'name',
      },
      {
        formField: 'input',
        name: 'Data rozpoczęcia',
        placeholder: 'Data rozpoczęcia',
        model: 'start_date',
      },
      {
        formField: 'input',
        name: 'Data zakończenia',
        placeholder: 'Data zakończenia',
        model: 'end_date',
      },
      {
        formField: 'input',
        name: 'Data zakończenia dodawania ogłoszeń',
        placeholder: 'Data zakończenia dodawania ogłoszeń',
        model: 'adverts_end_date',
      },
      {
        formField: 'checkbox',
        name: 'Aktywne',
        placeholder: 'Aktywne',
        model: 'visibility',
      },
    ]}}):_vm._e(),_c('table-data',{attrs:{"headers":[
      'ID',
      'Tytuł',
      'Data rozpoczęcia',
      'Data zakończenia',
      'Aktywne',
      'Koniec ogłoszeń',
    ],"tableData":this.editions.data,"adminCategory":'edition',"searchItem":'name'},on:{"start-editing":function($event){return _vm.startEditing($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }